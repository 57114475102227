import React, { useEffect, memo, useState } from "react"
import { useLazyLoadOnScroll } from "src/hooks/useLazyLoadOnScroll";
import { isElementInViewport } from "src/utils";
const AddWistiaScript = ({ videoStandardEmbedCode }) => {
  const shouldLoad = useLazyLoadOnScroll()
  const [isVisible, setIsVisible] = useState(false);

  useEffect(()=>{
      // Find the first element with the specific class name
      const elementWithClass = document.querySelector('.wistia_responsive_wrapper');
      if (elementWithClass) {
        setIsVisible(isElementInViewport(elementWithClass))
      }
  },[])

  useEffect(() => {
     if(!(shouldLoad || isVisible)) return undefined;
    try {
      const parser = new window.DOMParser();
      const doc = parser.parseFromString(videoStandardEmbedCode, "text/html");
      let scriptA = null, scriptW = null;
      let wistiaString = "https://fast.wistia.com";
      setTimeout(() => {
        let scriptWistia = doc.getElementsByTagName("script")[0]?.src
        let scriptAsset = doc.getElementsByTagName("script")[1]?.src
        let checkForWistiaScripts = scriptWistia?.includes(wistiaString) && scriptAsset?.includes(wistiaString)
        if (scriptWistia && scriptAsset && checkForWistiaScripts) {
          scriptW = document.createElement('script');
          scriptW.async = true;
          scriptW.src = scriptWistia;

          scriptA = document.createElement('script');
          scriptA.async = true;
          scriptA.src = scriptAsset;

          document.querySelector('head').appendChild(scriptW);
          document.querySelector('head').appendChild(scriptA);
        }

      }, 2000)

      return () => {
        if (scriptA) {
          scriptA.parentNode.removeChild(scriptA);
        }
        if (scriptW) {
          scriptW.parentNode.removeChild(scriptW);
        }
      }
    } catch (e) {
      console.log("Error while fetching script", e)
    }

  }, [isVisible, shouldLoad])

  return (
    null
  )
}
export default memo(AddWistiaScript);